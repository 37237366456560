export const getCouple = /* GraphQL */ `
  query GetCouple($id: ID!) {
    getCouple(id: $id) {
      id
      email
      name
      age
      partner_name
      partner_age
      profile_picture
      interests
      city
      about_us
      likes
      dislikes
      notifications
      push_tokens
      blocked_couples
      
    }
  }
`;

export const getCoupleLikesAndDislikes = /* GraphQL */ `
  query GetCouple($id: ID!) {
    getCouple(id: $id) {
      id
      likes
      dislikes
    }
  }
`;

export const getProfile = /* GraphQL */ `
  query GetCouple($id: ID!) {
    getCouple(id: $id) {
      id
      email
      name
      partner_name
      profile_picture
      interests
      city
      about_us
    }
  }
`;

export const getName = /* GraphQL */ `
  query GetCouple($id: ID!) {
    getCouple(id: $id) {
      name
      partner_name
    }
  }
`;

export const getPushTokens = /* GraphQL */ `
  query GetCouple($id: ID!) {
    getCouple(id: $id) {
      push_tokens
    }
  }
`;

export const getCoupleMatches = /* GraphQL */ `
  query MessagesByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coupleOne 
        coupleTwo
        messaged
      }
    }
  }
`;

export const getMatchesWithCouple = /* GraphQL */ `
  query MessagesByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messaged
        lastUpdatedBy
        unread
        coupleOneRef {
          id
          name
          partner_name
          profile_picture
          interests
          city
          notifications
          push_tokens
          email

        }
        coupleTwoRef {
          id
          name
          partner_name
          profile_picture
          interests
          city
          notifications
          push_tokens
          email
        }
      }
      nextToken
    }
  }
`;


export const getMessagesKey = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getChatsKey = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getAllEmails = /* GraphQL */ `
  query ListCouples(
    $filter: ModelCoupleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCouples(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        partner_name
      }
      nextToken
    }
  }
`;


export const listCouples = /* GraphQL */ `
  query ListCouples(
    $filter: ModelCoupleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCouples(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
      }
      nextToken
    }
  }
`;


export const chatsByDate = /* GraphQL */ `
  query ChatsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender {
          id
          name
          partner_name
          profile_picture
          city
          about_us
        }
        content
        type
        chatSenderId
        createdAt
      }
      nextToken
    }
  }
`;


