import { useState } from 'react';
import './App.css';
import { FroupleAdmin } from './FroupleAdmin';



function App() {
  
  
  

  return (
    <>
       <FroupleAdmin />
    </>
     
  );
}

export default App;
