/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateField = /* GraphQL */ `
  mutation UpdateField(
    $field: String
    $coupleKey: String
    $coupleToAdd: AWSJSON
  ) {
    updateField(field: $field, coupleKey: $coupleKey, coupleToAdd: $coupleToAdd)
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail(
    $sender: String
    $recipient: String
    $subject: String
    $content: String
  ) {
    sendEmail(
      sender: $sender
      recipient: $recipient
      subject: $subject
      content: $content
    )
  }
`;
export const adminDeleteCouple = /* GraphQL */ `
  mutation AdminDeleteCouple($username: String) {
    adminDeleteCouple(username: $username)
  }
`;
export const createCouple = /* GraphQL */ `
  mutation CreateCouple(
    $input: CreateCoupleInput!
    $condition: ModelCoupleConditionInput
  ) {
    createCouple(input: $input, condition: $condition) {
      id
      email
      name
      age
      partner_name
      partner_age
      profile_picture
      interests
      city
      about_us
      likes
      dislikes
      notifications
      push_tokens
      blocked_couples
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCouple = /* GraphQL */ `
  mutation UpdateCouple(
    $input: UpdateCoupleInput!
    $condition: ModelCoupleConditionInput
  ) {
    updateCouple(input: $input, condition: $condition) {
      id
      email
      name
      age
      partner_name
      partner_age
      profile_picture
      interests
      city
      about_us
      likes
      dislikes
      notifications
      push_tokens
      blocked_couples
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCouple = /* GraphQL */ `
  mutation DeleteCouple(
    $input: DeleteCoupleInput!
    $condition: ModelCoupleConditionInput
  ) {
    deleteCouple(input: $input, condition: $condition) {
      id
      email
      name
      age
      partner_name
      partner_age
      profile_picture
      interests
      city
      about_us
      likes
      dislikes
      notifications
      push_tokens
      blocked_couples
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      sender {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      recipient {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      content
      type
      createdAt
      updatedAt
      messageChatsId
      chatSenderId
      chatRecipientId
      owner
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      sender {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      recipient {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      content
      type
      createdAt
      updatedAt
      messageChatsId
      chatSenderId
      chatRecipientId
      owner
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      sender {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      recipient {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      content
      type
      createdAt
      updatedAt
      messageChatsId
      chatSenderId
      chatRecipientId
      owner
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      coupleOne
      coupleTwo
      coupleOneRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      coupleTwoRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      messaged
      type
      createdAt
      chats {
        items {
          id
          sender {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          recipient {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          content
          type
          createdAt
          updatedAt
          messageChatsId
          chatSenderId
          chatRecipientId
          owner
        }
        nextToken
      }
      unread
      lastUpdatedBy
      updatedAt
      messageCoupleOneRefId
      messageCoupleTwoRefId
      owner
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      coupleOne
      coupleTwo
      coupleOneRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      coupleTwoRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      messaged
      type
      createdAt
      chats {
        items {
          id
          sender {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          recipient {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          content
          type
          createdAt
          updatedAt
          messageChatsId
          chatSenderId
          chatRecipientId
          owner
        }
        nextToken
      }
      unread
      lastUpdatedBy
      updatedAt
      messageCoupleOneRefId
      messageCoupleTwoRefId
      owner
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      coupleOne
      coupleTwo
      coupleOneRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      coupleTwoRef {
        id
        email
        name
        age
        partner_name
        partner_age
        profile_picture
        interests
        city
        about_us
        likes
        dislikes
        notifications
        push_tokens
        blocked_couples
        type
        createdAt
        updatedAt
        owner
      }
      messaged
      type
      createdAt
      chats {
        items {
          id
          sender {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          recipient {
            id
            email
            name
            age
            partner_name
            partner_age
            profile_picture
            interests
            city
            about_us
            likes
            dislikes
            notifications
            push_tokens
            blocked_couples
            type
            createdAt
            updatedAt
            owner
          }
          content
          type
          createdAt
          updatedAt
          messageChatsId
          chatSenderId
          chatRecipientId
          owner
        }
        nextToken
      }
      unread
      lastUpdatedBy
      updatedAt
      messageCoupleOneRefId
      messageCoupleTwoRefId
      owner
    }
  }
`;
