import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import { FroupleModal } from './FroupleModal';
import { getAllEmails } from '../src/graphql/custom/queries';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'



import { useEffect, useState} from 'react';

import { Auth, API, Storage, graphqlOperation } from 'aws-amplify';
import { listCouples } from '../src/graphql/custom/queries';
import { sendEmail, deleteMessage, deleteCouple, updateCouple, adminDeleteCouple } from '../src/graphql/mutations';
import { getMessagesKey, getChatsKey } from '../src/graphql/custom/queries';

const S3URL = "https://frouple96e9947e36934e19bf915eba7f25b1c5113431-dev.s3.eu-central-1.amazonaws.com/public/"
var couplesList = []

export const FroupleAdmin = () => {
  //user login status
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loggedIn, setLoggedIn] = useState(false)
  const [admin, setAdmin] = useState(null)
  const [showBottomLoginForm, setShowBottomLoginForm] = useState(false)

  //spinner and popover
  const [spinner, setSpinner] = useState(false)

  //couples
  const [couples, setCouples] = useState([])
  const [nextToken, setNextToken] = useState(null)

  //search form
  const [name, setName] = useState("")
  const [partnerName, setPartnerName] = useState("")
  const [city, setCity] = useState("")


  //alert
  const [variant, setVariant] = useState("success")
  const [alertText, setAlertText] = useState("")
  const [showAlert, setShowAlert] = useState(false)

  //current couple data
  const [url, setUrl] = useState("")
  const [profileEmail, setProfileEmail] = useState("")
  const [profileName, setProfileName] = useState("")
  const [profilePartnerName, setProfilePartnerName] = useState("")

  //picture modal
  const [pictureModalLoading, setPictureModalLoading] = useState(false)
  const [showPictureModalAlert, setShowPictureModalAlert] = useState(false)
  const [pictureModalAlertMessage, setPictureModalAlertMessage] = useState("")

  //const sendEmail modal
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [emailModalLoading, setEmailModalLoading] = useState(false)
  const [showEmailModalAlert, setShowEmailModalAlert] = useState(false)
  const [emailModalAlertMessage, setEmailModalAlertMessage] = useState("")

  //email all users
  const [showEmailAllModal, setShowEmailAllModal] = useState(false)
  const [emailAllModalLoading, setEmailAllModalLoading] = useState(false)
  const [showEmailAllModalAlert, setShowEmailAllModalAlert] = useState(false)
  const [emailAllModalAlertMessage, setEmailAllModalAlertMessage] = useState("")
  const LIMIT = 20

  //delete profile


  //login

  const logoutUser = async() => {
    setShowAlert(false)
    setSpinner(true)
    try {
      await Auth.signOut();
      setAdmin(null)
      setLoggedIn(false)
      setSpinner(false)
      setShowBottomLoginForm(false)
    } catch (error) {
      setAlertText("Could not logout user - " + error.message)
      setVariant("danger")
      setShowAlert(true)
      setLoggedIn(true)
      setSpinner(false)
    }
  }

  const loginUser = async() => {
    setShowAlert(false)
    setSpinner(true)
    //validate inputs
    if(email === ""){
      setAlertText("Please enter a valid email address")
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
      return
    }
    if(password === ""){
      setAlertText("Please enter a valid password")
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
      return
    }

    //allow login for only talkwithcharles
    const admins = ["talkwithcharles@gmail.com", "mari-jarju@outlook.com"]
    if(! admins.includes(email)){
      setAlertText("You are not permitted to use this website")
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
      return;
    }

    //login the user
    try {
      const cognitoUser = await Auth.signIn(email, password)
      setAdmin(cognitoUser)
      setLoggedIn(true)
      setSpinner(false)
    } catch (error) {
      setAlertText("Could not login - " + error.message)
      setVariant("danger")
      setShowAlert(true)
      setLoggedIn(false)
      setSpinner(false)
    }

  }

  const handleResetButtonClick = () => {
    setCouples([...couplesList])
  }

  const handleCouplesButtonClick = () => {
    /*fetch couples
      const query = {
        limit: LIMIT,
        filter: {
          id: {
            ne: admin.username
          }
        }
      }
      getCouples(query) */

      //fetch couples

    if(nextToken === null && couples.length > 0){
      setAlertText("You have reach the end of couples list.")
      setVariant("info")
      setShowAlert(true)

    }else{
      const query = {
        limit: LIMIT,
        nextToken: nextToken,
        filter: {
          id: {
            ne: admin.username
          }
        }
      }
      getCouples(query)
    }

  }

  //get all couples

  const getCouples = async(query) => {
    setSpinner(true)
    try {
      setShowAlert(false)
      var result = await API.graphql(graphqlOperation(listCouples, query));
      setCouples([...result.data.listCouples.items, ...couples])
      result.data.listCouples.items.map(i => {
        couplesList.unshift(i)
      })
      setNextToken(result.data.listCouples.nextToken)
      setSpinner(false)
    } catch (error) {
      setAlertText("Could not fetch couples " + error.message)
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
    }
  }

 
  const filterTable = (e) => {
    const updated = couplesList.filter(c => {
      if((c.city && c.city.toLowerCase().match(e.toLowerCase())) || 
        (c.name && c.name.toLowerCase().match(e.toLowerCase())) || 
        (c.partner_name && c.partner_name.toLowerCase().match(e.toLowerCase())) || 
        (c.email && c.email.toLowerCase().match(e.toLowerCase())))
      {
        return c
      }
    })

    if(updated.length > 0){
      setCouples([...updated])
    }else{
      setCouples([...couplesList])
    }
  }




  const findCouple = async() => {
    setSpinner(true)
    //validate inputs
    if(name === ""){
      setAlertText("Please enter couple name")
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
      return
    }
    if(partnerName === ""){
      setAlertText("Please enter couple parner name")
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
      return
    }

    const query = {
      filter: {
        or: [
          {
            and: [
              {
                name: {
                  eq: partnerName
                },
                partner_name: {
                  eq: name
                }
              }
            ]
          },
          {
            and: [
              {
                name: {
                  eq: name
                },
                partner_name: {
                  eq: partnerName
                }
              }
            ]
          }
        ]
      }
    }

    try {
      setShowAlert(false)
      var result = await API.graphql(graphqlOperation(listCouples, query));
      if(result.data.listCouples.items.length < 1){
        setAlertText("Could not find a profile with the specified parameters")
        setVariant("danger")
        setShowAlert(true)
      }else{
        setCouples([...result.data.listCouples.items])
      }
      setSpinner(false)
      
    } catch (error) {
      setAlertText("Could not find Couple " + error.message)
      setVariant("danger")
      setShowAlert(true)
      setSpinner(false)
    }
  }


  useEffect(() => {
    if(loggedIn === true){
      setShowEmailModal(true)
    }

  }, [profileName, profilePartnerName])

  const handleEmailClick = (couple) => {
    setProfileEmail(couple.email);
    setProfileName(couple.name)
    setProfilePartnerName(couple.partner_name)
    setEmailModalLoading(false); 
    setShowEmailModalAlert(false);
    if(couple.email === profileEmail){
      setShowEmailModal(true)
    }
  }

  const handleSendEmail = async(message, subject) => {
    if(subject.length < 5 || subject ==="" || subject === null || subject ===undefined){
      setEmailModalAlertMessage("Please enter a valid email subject")
      setShowEmailModalAlert(true)
      return;
    }

    if(message.length < 5 || message==="" || message === null || message === undefined){
      setEmailModalAlertMessage("Please enter a valid email message")
      setShowEmailModalAlert(true)
      return;
    }

    try{
      setEmailModalLoading(true)
      const params = {
        sender: "noreply@frouple.com",
        recipient: profileEmail,
        subject: subject,
        content: message
      }

      await API.graphql(graphqlOperation(sendEmail, params))
      setEmailModalLoading(false)
      setShowEmailModal(false)

      //show main page alert
      setAlertText("Email has been successfully sent!")
      setVariant("success")
      setShowAlert(true)

    }catch(error){
      console.log(error)
      setEmailModalLoading(false)
      setEmailModalAlertMessage("Error sending email. Please try again later!")
      setShowEmailModalAlert(true)
    }

  }

  const handleCloseModal = () => {
    setShowEmailModal(false)
    setShowEmailAllModal(false)
  }

  //email all modal
  const handleShowEmailAllModal = () => {
    setShowAlert(false)
    setShowEmailAllModal(true)
    setEmailAllModalLoading(false)
    setShowEmailAllModalAlert(false)
  }

  const sendMessage = async(params) => {
    try{
      setShowEmailModalAlert(false)
      await API.graphql(graphqlOperation(sendEmail, params))
    }catch(error){
      setEmailAllModalAlertMessage("Error sending email to " + params.recipient)
      setShowEmailModalAlert(true)
    }
  }


  const getCouplesData = async() => {
    try {
      setEmailAllModalLoading(true)
      var nextToken = null;
      var data = []

      do{
        var queryString = {}

        const filter = {
          or: [
            {
              email: {
                ne: "Unknown"
              },
            }
          ]
        }
        if(nextToken === null){
          queryString = {
            limit: LIMIT,
            filter: filter
          }
        }else{
          queryString = {
            limit: LIMIT,
            nextToken: nextToken,
            filter: filter
          }
        }
        const results = await API.graphql(graphqlOperation(getAllEmails, queryString))
        var couplesTemp = results.data.listCouples.items
        nextToken = results.data.listCouples.nextToken
        data = [...data, ...couplesTemp]

      }while(nextToken !== null)
      return data

    } catch (error) {
      setEmailAllModalAlertMessage("Error sending email. Please try again later!")
      setShowEmailModalAlert(true)
      
    }
  }


  const handleSendEmailToAll = async(message, subject) => {
    if(subject.length < 5 || subject ==="" || subject === null || subject ===undefined){
      setEmailAllModalAlertMessage("Please enter a valid email subject")
      setShowEmailAllModalAlert(true)
      return;
    }

    if(message.length < 5 || message==="" || message === null || message === undefined){
      setEmailAllModalAlertMessage("Please enter a valid email message")
      setShowEmailAllModalAlert(true)
      return;
    }


    const data = await getCouplesData()

    try {
      
      data.map(couple => {
        let updatedMessage = message.replace("<b>[NAMES_TEMPLATE]</b>", couple.name + ' & ' + couple.partner_name)
        const params = {
          sender: "noreply@frouple.com",
          recipient: couple.email,
          subject: subject,
          content: updatedMessage
        }
        sendMessage(params)
      })

      setEmailAllModalLoading(false)
      setShowEmailAllModal(false)

      //show main page alert
      setAlertText("Emails have been potentially successfully sent!")
      setVariant("success")
      setShowAlert(true)
      
    } catch (error) {
      setEmailAllModalLoading(false)
      setEmailAllModalAlertMessage("Error sending email. Please try again later!")
      setShowEmailModalAlert(true)
    }
  }


  //delete couple
  //dynamodb has limit on the number of records return. We want to be sure to get all messages/chats for the current user before attempting delete
  
  const getAllMessagesId = async(couple_id) => {
    var queryParam = {}
    var messages = []
    let done = false
    let startKey = null

    do{
      if(startKey === null){
        queryParam = {
          filter: {
            or: [
              { 
                coupleOne:{
                  eq: couple_id
                },
              },
              {
                coupleTwo:{
                  eq: couple_id
                },
              }
            ]
          },
          startKey: startKey
        }
      }else{
        queryParam = {
          filter: {
            or: [
              { 
                coupleOne:{
                  eq: couple_id
                },
              },
              {
                coupleTwo:{
                  eq: couple_id
                },
              }
            ]
          },
          nextToken: startKey,
        }
      }

      try {
        const response = await API.graphql(graphqlOperation(getMessagesKey, queryParam))
			  const messageItems = response.data.listMessages.items

        messageItems.map(item => {
          messages.push(item.id)
        })

        if(response.data.listMessages.nextToken){
          startKey = response.data.listMessages.nextToken
          done = false
        }else{
          done = true
        }
      } catch (error) {
        console.log(error)
      }
    }while(done === false)

    return messages
  }

  const getAllChatsId = async(couple_id) => {
    var queryParam = {}
    var chats = []
    let done = false
    let startKey = null

    do{
      if(startKey === null){
        queryParam = {
          filter: {
            or: [
              { 
                chatSenderId:{
                  eq: couple_id
                },
              },
              {
                chatRecipientId:{
                  eq: couple_id
                },
              }
            ]
          },
          startKey: startKey
        }
      }else{
        queryParam = {
          filter: {
            or: [
              { 
                chatSenderId:{
                  eq: couple_id
                },
              },
              {
                chatRecipientId:{
                  eq: couple_id
                },
              }
            ]
          },
          nextToken: startKey,
        }
      }

      try {
        const response = await API.graphql(graphqlOperation(getChatsKey, queryParam))
			  const chatItems = response.data.listChats.items
        chatItems.map(item => {
          chats.push(item.id)
        })

        if(response.data.listChats.nextToken){
          startKey = response.data.listChats.nextToken
          done = false
        }else{
          done = true
        }
      } catch (error) {
        console.log(error)
      }
    }while(done === false)

    return chats

  }


  const removeMessage = async(messageId) => {

    const queryParam = {
      input: {
        id : messageId
      }
    }

    const result = await API.graphql(graphqlOperation(deleteMessage, queryParam))
    if(result.data.deleteMessage === null){
      //send email to frouple admin if anything goes wrong
      const params = {
        sender: "noreply@frouple.com",
        recipient: "talkwithcharles@gmail.com",
        subject:  "Error - Frouple Amin Web",
        content: "Unable to remove messages with ID: " + messageId
      }
      sendEmailOnDeleteFail(params)
      setAlertText("Could not delete user messages - Frouple Admin has been automatically informed")
      setVariant("danger")
      setShowAlert(true)
    }

    
  
  }

  const removeCouple = async(coupleId) => {
    
    const queryParam = {
      input: {
        id : coupleId,
      }
    }
    const results = await API.graphql(graphqlOperation(deleteCouple, queryParam))
    if(results.data.deleteCouple === null){
      //send email to frouple admin if anything goes wrong
      const params = {
        sender: "noreply@frouple.com",
        recipient: "talkwithcharles@gmail.com",
        subject:  "Error - Frouple Amin Web",
        content: "Unable to delete user with ID: " + coupleId
      }
      sendEmailOnDeleteFail(params)

      setAlertText("Could not delete user - Frouple Admin has been automatically informed")
      setVariant("danger")
      setShowAlert(true)
    }
  }

  const editCouple = async(coupleId) => {

    const queryParam = {
      input: {
        id : coupleId,
        email: "Unknown",
        name: "DeletedCoupleName",
        age: 0,
        partner_name: "",
        partner_age: 0,
        profile_picture: "deleted_user_profile_picture.jpg",
        interests: [],
        city: "Unknown",
        about_us: "",
        likes: [],
        dislikes: [],
        blocked_couples: [],
        notifications: [],
        push_tokens: [],
      }
    }
    const result = await API.graphql(graphqlOperation(updateCouple, queryParam))
    
    if(result.data.updateCouple === null){
      //send email to frouple admin if anything goes wrong
      const params = {
        sender: "noreply@frouple.com",
        recipient: "talkwithcharles@gmail.com",
        subject:  "Error - Frouple Amin Web",
        content: "Unable to update user with Id: " + coupleId
      }
      sendEmailOnDeleteFail(params)
      setAlertText("Could not update couple - Frouple Admin has been automatically informed")
      setVariant("danger")
      setShowAlert(true)
    }
  }

  const removePicture = async(picture) => {
      await Storage.remove(picture).then(data => {
        if(data.$metadata.httpStatusCode !== 204){
           //send email to frouple admin if anything goes wrong
          const params = {
            sender: "noreply@frouple.com",
            recipient: "talkwithcharles@gmail.com",
            subject:  "Error - Frouple Amin Web",
            content: "Unable to delete profile picture from s3: " + picture
          }
          sendEmailOnDeleteFail(params)
          setAlertText("Could not delete profile picture from database - Frouple Admin has been automatically informed")
          setVariant("danger")
          setShowAlert(true)
        }
      })
  }

  const sendEmailOnDeleteFail = async(params) => {
    try{
      await API.graphql(graphqlOperation(sendEmail, params))
    }catch(error){
      setAlertText("Could not send info to Frouple Admin")
      setVariant("danger")
      setShowAlert(true)
    }
    
  }

  const removeCognito = async(username) => {

    const params = {
      username: username
    }

    await API.graphql(graphqlOperation(adminDeleteCouple, params))
      .then(result => {
        if(result.data.adminDeleteCouple !== "Done deleting cognito user"){
          //send email to frouple admin if anything goes wrong
          const params = {
            sender: "noreply@frouple.com",
            recipient: "talkwithcharles@gmail.com",
            subject: "Error - Frouple Amin Web",
            content: "Unable to remove cognito user with email - " + username
          }
          sendEmailOnDeleteFail(params)
          setAlertText("Could not remove cognito user - Frouple Admin has been automatically informed")
          setVariant("danger")
          setShowAlert(true)
        }
      })
  }



  const handleDeleteProfile = async(couple) => {

    setSpinner(true)


    const matches = await getAllMessagesId(couple.id)

    if(matches.length < 1){
      //no matches/messages so delete user
      await removeCouple(couple.id)
      await removePicture(couple.profile_picture)
      await removeCognito(couple.id)

      setAlertText("Profile has been successfully deleted.")
      setVariant("success")
      setShowAlert(true)
      
    }else{
      //user has matches so check for chats
      const chats = await getAllChatsId(couple.id)
      if(chats.length < 1){
        //no chats so delete user and match
        matches.map(msgId => {
          removeMessage(msgId)
        })
        await removeCouple(couple.id)
        await removePicture(couple.profile_picture)
        await removeCognito(couple.id)

        setAlertText("Profile has been successfully deleted.")
        setVariant("success")
        setShowAlert(true)

      }else{
        //user has chats and messages so keep chats/messages and update user details
        await editCouple(couple.id)
        await removePicture(couple.profile_picture)
        await removeCognito(couple.id)

        setAlertText("Profile has been successfully deleted.")
        setVariant("success")
        setShowAlert(true)

      }
    }

    const newList = couples.filter(c => {
      return c.id !== couple.id
    })

    setCouples([...newList])


    setSpinner(false)


    //const matches = await getAllMessagesId(couple_id)
    //https://stackoverflow.com/questions/64533404/lambda-user-is-not-authorized-to-perform-cognito-idplistusers

  }

  const updateAcouple = async(dataToUpdate) => {
    try {
      const result = await API.graphql(graphqlOperation(updateCouple, dataToUpdate));
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateAllClick = () => {
    couples.map(item => {
        console.log("Updating could with email: " + item.email)
        const dataToUpdate = {
          input: {
            id: item.id,
            photos: [],
          }
        }
        updateAcouple(dataToUpdate)
      
      
    })
    console.log("Done updating...")
  }


  return (
    <>
      
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand >
            <img style={{width: '50%'}} src='https://frouple96e9947e36934e19bf915eba7f25b1c5113431-dev.s3.eu-central-1.amazonaws.com/public/frouple_logo_text.png' alt='Frouple logo' />
            </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {loggedIn && 
              <>
                <Nav.Link href="#" onClick={handleShowEmailAllModal}>Email All</Nav.Link>
                <Nav.Link href="#" onClick={handleCouplesButtonClick}>Couples <Badge bg="secondary">{couples.length}</Badge></Nav.Link>
                {/*<Nav.Link href="#" onClick={handleUpdateAllClick}>Update All</Nav.Link> */}
              </>
               
              }
            </Nav>
            
            
            {loggedIn ? (
              <>
                <Form>
                  <Row>
                    <Col sm={12} md={3} className='mb-2'>
                      <Form.Control
                          type="text"
                          placeholder="Name"
                          className="me-2"
                          aria-label="Name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                    </Col>
                    <Col sm={12} md={3} className='mb-2'>
                      <Form.Control
                          type="text"
                          placeholder="Partner name"
                          className="me-2"
                          aria-label="Partner name"
                          value={partnerName}
                          onChange={e => setPartnerName(e.target.value)}
                        />
                    </Col>
                    <Col sm={12} md={"auto"}  className='mb-2'>
                      <Button variant="outline-success" onClick={findCouple}>Search</Button>
                    </Col>
                    <Col  sm={12} md={"auto"}>
                      <Button  variant="outline-danger" onClick={logoutUser}>Logout</Button>
                    </Col>
                  </Row>
                </Form>
              </>
              
            ):(
              
              <Form className="d-flex">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="me-2"
                  aria-label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="me-2"
                  aria-label="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <Button variant="outline-success" onClick={loginUser}>Login</Button>
              </Form>
            )}
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='container-fluid'>
        {spinner &&
          <div className="d-flex justify-content-center mb-3 mt-3">
            <Spinner animation="border" role="status" variant="primary" />
          </div>
        }
        {!loggedIn &&
          <div className='mt-3 mb-5 px-5 py-5' style={{background: '#f3f3f1', borderRadius: 20}}>
            <h1>Hello, Frouple Admin</h1>
            <p className ="lead">Welcome to Frouple Admin ONLY website where Admins can carry out administrative duties. </p>
            <hr className="my-4"></hr>
            <p>Features includes - Listing all profiles, Emailing all profiles, Emailing a single profile and deleting a profile.</p>
            {!showBottomLoginForm &&
              <p className="lead mt-4">
                <Button variant="outline-secondary" onClick={() => setShowBottomLoginForm(true)}>Login to get started</Button>
              </p>
            }
            
            {showBottomLoginForm &&
              <Stack className="mt-4" direction='vertical' gap={3} style={{maxWidth: "300px"}}>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="me-2"
                  aria-label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="me-2"
                  aria-label="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <Button variant="outline-success" onClick={loginUser}>Login</Button>
              </Stack>
            }
            
          </div>
        }
        {showAlert && 
          <Alert key={variant} variant={variant}>
            {alertText}
          </Alert>
        }

        {loggedIn && 
          <>
          {couples.length > 0 &&
            <>
            <div className="d-flex justify-content-between mt-5">
              <Button className="btn-sm" variant="outline-danger" onClick={handleResetButtonClick}>Reset</Button>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="search"
                  aria-label="Search"
                  onChange={e => filterTable(e.target.value)}
                />
              </Form>
            </div>
              
           
            <Table striped bordered className="mt-2" >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Partner</th>
                  <th>Email</th>
                  <th>City</th>
                  <th>Picture</th>
                  <th>Bio</th>
                </tr>
              </thead>
              <tbody>
                {couples.map(item => {
                  return <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.partner_name}</td>
                    <td>{item.email}</td>
                    <td>{item.city}</td>
                    <td>
                      {/*<Button size="sm" variant="link" onClick={() => showPicture(item.profile_picture)}>View picture</Button>*/}
                      <OverlayTrigger 
                        trigger="click" 
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>
                              {showPictureModalAlert &&
                                <Alert key="danger" variant="danger">
                                  {pictureModalAlertMessage}
                                </Alert>
                              }

                              {pictureModalLoading &&
                                <div className="d-flex justify-content-center mb-3">
                                  <Spinner animation="border" role="status" variant="primary" />
                                </div>
                              }
                              {url &&
                                <Image src={url} fluid="true" />
                              }
                            </Popover.Body>
                          </Popover>
                        }>
                        <Button size="sm" variant="link" onClick={() => setUrl(`${S3URL}${item.profile_picture}`)}>Display</Button>
                      </OverlayTrigger>

                    </td>
                    <td>
                      {item.about_us !== null && item.about_us.length > 30 ? (
                        item.about_us.slice(0,30) + "..."
                      ) : (
                        item.about_us
                      )}
                    </td>
                    <td> <Button size="sm" variant="link" onClick={() => handleEmailClick(item)}>Email</Button></td>
                    <td>
                      <OverlayTrigger 
                        trigger="click" 
                        placement="top"
                        rootClose
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>
                              Are you sure? <Button variant="outline-danger" size='sm' onClick={() => handleDeleteProfile(item)}>Yes</Button>
                            </Popover.Body>
                          </Popover>
                        }>
                        <Button size="sm" variant="link" onClick={() => {setShowAlert(false)}}>Delete</Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                })}
                
              </tbody>

            </Table>
            </>
          }
          
          </>
        }

        <FroupleModal 
          show={showEmailModal}
          name={profileName}
          partnerName={profilePartnerName}
          showName={true}
          loading={emailModalLoading}
          showAlert={showEmailModalAlert}
          alertMessage={emailModalAlertMessage}
          handleClose={handleCloseModal}
          handleSendEmail={handleSendEmail}
        />

        <FroupleModal 
          show={showEmailAllModal}
          name={name}
          loading={emailAllModalLoading}
          showAlert={showEmailAllModalAlert}
          alertMessage={emailAllModalAlertMessage}
          handleClose={handleCloseModal}
          handleSendEmail={handleSendEmailToAll}
        />

      </div> 
    </>

    
    
  )
}