import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Editor } from 'react-bootstrap-editor';


const defaultText = "<b>[EDIT ME]</b><br/> Unfortunately, your profile is violating our terms and conditions. Please change your violating text within 24 hours. Otherwise, we are forced to delete your profile"
  
export const FroupleModal = ({show, name, partnerName, showName, handleClose, loading, handleSendEmail, showAlert, alertMessage}) => {

  const [subject, setSubject] = useState("Email subject here")
  const [message, setMessage] = useState(` \
            <div style='width: 100%; background-color: #E5E5E5'> \
              <div style='max-width: 540px; font-family: Arial, Helvetica, sans-serif; margin: 0px auto;'> \
                <div style='background-color: white; padding: 40px;'> \
                  <img src='https://frouple96e9947e36934e19bf915eba7f25b1c5113431-dev.s3.eu-central-1.amazonaws.com/public/frouple_logo_text.png' alt='Frouple logo' /> \
                  ${showName ? (
                    `<div style='margin-top: 48px; font-size: 16px'>Hi ${name} & ${partnerName}, </div>`
                  ): (
                    `<div style='margin-top: 48px; font-size: 16px'>Hi <b>[NAMES_TEMPLATE]</b>, </div>`
                  )} \
                  <div style='margin-top: 28px; font-size: 16px'>${defaultText}</div> \
                  <div style='margin-top: 28px; font-size: 16px;'>Regards,</div><div style='margin-top: 10px; font-size: 16px;'>Frouple Team.</div> \
                  <hr style='margin-top: 28px;' />
                  <div style='margin-top: 28px; font-size: 14px;'><strong>Important:</strong> Get the updated app for your mobile platform to enjoy the new capabilities.</div> \
                  <div style='margin-top: 28px'> \
                    <a style='text-decoration: none; background: #87B5DB; color: white; border-radius: 20px; padding: 8px 14px; font-size: 12px;' href='https://apps.apple.com/app/frouple/id1617327336'>App store</a> \
                  </div> \
                  <div style='margin-top: 28px'> \
                    <a style='text-decoration: none; background: #87B5DB; color: white; border-radius: 20px; padding: 8px 14px; font-size: 12px;' href='https://play.google.com/store/apps/details?id=com.frouple.web'>Google play</a> \
                  </div> \
                  <hr style='margin-top: 38px;' />
                  <div style='margin-top: 28px; color: #929292; font-size: 12px;'> \
                    By using Frouple, you agree to our <a href='https://frouple.com/privacy_policy' target='_blanck'> Privacy Policy </a> and <a href='https://frouple.com/terms_of_use' target='_blanck'>Terms of Use.</a> \
                  </div> \
                  <div style='margin-top: 18px; color: #929292; font-size: 12px;'>&copy; ${new Date().getFullYear()} Frouple UG (haftungsbeschränkt). </div> \
                </div> \
              </div> \
            </div> \
            `)


  useEffect(() => {
    setSubject("Email subject here")
    setMessage(` \
            <div style='width: 100%; background-color: #E5E5E5'> \
              <div style='max-width: 540px; font-family: Arial, Helvetica, sans-serif; margin: 0px auto;'> \
                <div style='background-color: white; padding: 40px;'> \
                  <img src='https://frouple96e9947e36934e19bf915eba7f25b1c5113431-dev.s3.eu-central-1.amazonaws.com/public/frouple_logo_text.png' alt='Frouple logo' /> \
                  ${showName ? (
                    `<div style='margin-top: 48px; font-size: 16px'>Hi ${name} & ${partnerName}, </div>`
                  ): (
                    `<div style='margin-top: 48px; font-size: 16px'>Hi <b>[NAMES_TEMPLATE]</b>, </div>`
                  )} \
                  <div style='margin-top: 28px; font-size: 16px'>${defaultText}</div> \
                  <div style='margin-top: 28px; font-size: 16px;'>Regards,</div><div style='margin-top: 10px; font-size: 16px;'>Frouple Team.</div> \
                  <hr style='margin-top: 28px;' />
                  <div style='margin-top: 28px; font-size: 14px;'><strong>Important:</strong> Get the updated app for your mobile platform to enjoy the new capabilities.</div> \
                  <div style='margin-top: 28px'> \
                    <a style='text-decoration: none; background: #87B5DB; color: white; border-radius: 20px; padding: 8px 14px; font-size: 12px;' href='https://apps.apple.com/app/frouple/id1617327336'>App store</a> \
                  </div> \
                  <div style='margin-top: 28px'> \
                    <a style='text-decoration: none; background: #87B5DB; color: white; border-radius: 20px; padding: 8px 14px; font-size: 12px;' href='https://play.google.com/store/apps/details?id=com.frouple.web'>Google play</a> \
                  </div> \
                  <hr style='margin-top: 38px;' />
                  <div style='margin-top: 28px; color: #929292; font-size: 12px;'> \
                    By using Frouple, you agree to our <a href='https://frouple.com/privacy_policy' target='_blanck'> Privacy Policy </a> and <a href='https://frouple.com/terms_of_use' target='_blanck'>Terms of Use.</a> \
                  </div> \
                  <div style='margin-top: 18px; color: #929292; font-size: 12px;'>&copy; ${new Date().getFullYear()} Frouple UG (haftungsbeschränkt). </div> \
                </div> \
              </div> \
            </div> \
            `)
  }, [show, name, partnerName])
  return (
    <>
      <Modal show={show}>
        <Modal.Body>
          
          {showAlert &&
            <Alert key="danger" variant="danger">
              {alertMessage}
            </Alert>
          }
          {loading &&
          <div className="d-flex justify-content-center mb-3">
             <Spinner animation="border" role="status" variant="primary" />
          </div>
           
          }

          <Form>
            <Form.Group className="mb-3" controlId="subject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email subject here"
                className="me-2 mb-3"
                aria-label="Subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="subject">
              <Form.Label>Message</Form.Label>
              <Editor defaultValue={message} value={message} onChange={setMessage} />
            </Form.Group>

          </Form>
            
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={() => handleSendEmail(message, subject)}>
            Send
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
